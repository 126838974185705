import React from 'react'
import './Header.scss';
import { Button, Dialog, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import { IUser } from '../../context/models';
import { useAppContext } from '../../context/AppContext';
import { useNavigate } from 'react-router-dom';
import { BASE_ADMIN_URL } from '../../services/config';

export interface IHeaderProps {
  user: IUser;
}



function Header({ user }: IHeaderProps) {

  const { brandList, masterData } = useAppContext();
  console.log('useAppContext ~~ Header~~~~ brandList', brandList)
  const [open, setOpen] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);

  const [searchText, setSearchText] = React.useState('');

  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(!openMenu);
  };
  const handleClickMheader = () => {
    setOpenMenu(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const handleSearch = () => {
    setOpen(false);
    if (searchText) {
      navigate('search?text=' + searchText);
    }
  };

  const handleNavigation = (path: string) => {
    navigate(path);  // Navigate to the "Brand" page
    setOpenMenu(false);
  };

  // const { userName } = useAppContext()

  const imgUrl = BASE_ADMIN_URL + masterData?.lOLMasterData?.headerLogo[0]?.path || '';
  return (
    <>
      <div className="header">
        <div className="header-container">
          <img src={imgUrl} alt="Forage Genetics" className='logo' onClick={() => handleNavigation('/')} />
          <div className='brands'>
            {brandList?.map((brand, index) => (
              <div key={index} className='brand' onClick={() => handleNavigation(brand.nodeAlias)}>

                {brand.headerLogo.length > 0 ?
                  <img className='icon' src={BASE_ADMIN_URL + brand.headerLogo[0].path} alt={brand.headerLogo[0].fileName} /> :
                  <span className='text'>{brand.title}</span>
                }
              </div>
            ))}
            <IconButton aria-label="Search" className='search' size="small" onClick={handleClickOpen}>
              <i className={`fgi fgi-magnifying-glass`}></i>
            </IconButton>
          </div>
          <div className='user'>
            <div className='user-name'> Welcome, {user.name}</div>
            {/* <div className='user-logout'>Logout</div> */}
          </div>
        </div>
        <div className="header-container-mobile">
          {openMenu ?
            (<IconButton aria-label="more" className='more' size="small" onClick={handleMenuClose}>
              <i className={`fgi fgi-close`}></i>

            </IconButton>
            ) :
            <IconButton aria-label="more" className='more' size="small" onClick={handleClickMheader}>
              <i className={`fgi fgi-menu`}></i>
            </IconButton>
          }


          <img src={imgUrl} alt="Forage Genetics" className='logo' onClick={() => handleNavigation('/')} />

          <IconButton aria-label="Search" className='search' size="small" onClick={handleClickOpen}>
            <i className={`fgi fgi-magnifying-glass`}></i>
          </IconButton>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        className='search-dialog'
      >
        <OutlinedInput
          id="search-input"
          aria-label="Search Input"
          type={'text'}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          className='search-dialog-input-container'
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                className='search-input-action'
                aria-label="Search"
                onClick={handleSearch}
                // onMouseDown={handleSearch}
                // onMouseUp={handleSearch}
                edge="end"
              >
                <i className={`fgi fgi-magnifying-glass`}></i>
              </IconButton>
            </InputAdornment>
          }
        />
      </Dialog>
      <Dialog
        fullScreen
        open={openMenu}
        // onClose={handleMenuClose}
        className='menu-dialog'
      >
        <div className='brands-mobile'>
          {brandList?.map((brand, index) => (
            <div key={index} className='brand-mobile' onClick={() => handleNavigation(brand.nodeAlias)}>
              {brand.headerLogo.length > 0 ?
                <img className='icon' src={BASE_ADMIN_URL + brand.headerLogo[0].path} alt={brand.headerLogo[0].fileName} /> :
                <span className='text'>{brand.title}</span>
              }
            </div>
          ))}
          {/* <Button className='logout-mobile' variant="contained">Logout User</Button> */}

        </div>
      </Dialog>
    </>
  );
}

export default Header;

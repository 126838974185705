import {
    ApolloClient,
    InMemoryCache,
    createHttpLink
} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { API_CLIENT_URL } from "./config";

const httpLink = createHttpLink({
    uri: API_CLIENT_URL,
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            authorization: `Bearer ${process.env.REACT_APP_FGI_DXP_API_BEARER_TOKEN}`,
        }
    }
});

export const dxpClient =
    new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache()
    });
import React, { createContext, PropsWithChildren, useContext, useEffect, useState, useMemo } from 'react';
import { IBrands, IBrandsData, ILOLMasterData } from './models';
import { useQuery } from '@apollo/client';
import { BRAND_QUERY, MASTERDATA_QUERY } from '../services/query';
import Loader from '../components/Loader';

export interface IAppContext {
  brandList?: IBrands[];
  masterData?: ILOLMasterData;
  userName: string;
  setUserName: React.Dispatch<React.SetStateAction<string>>;
  token: string;
  setToken: React.Dispatch<React.SetStateAction<string>>;
  loader: string[];
  setLoader: React.Dispatch<React.SetStateAction<string[]>>;
}
const defaultState = {} as IAppContext;

const AppContext = createContext<IAppContext>(defaultState);

export const useAppContext = (): IAppContext => useContext(AppContext);

const AppContextProvider = ({ children }: PropsWithChildren<{}>): JSX.Element => {
  const [token, setToken] = useState<string>('');
  const [loader, setLoader] = useState<string[]>([]);
  const [brandList, setBrandList] = useState<IBrands[]>();
  const [masterData, setMasterData] = useState<ILOLMasterData>();
  const [userName, setUserName] = useState<string>(''); 
  
  // Helper function to manage the loader state
  const checkLoading = (isLoading: boolean, loaderName: string) => {
    setLoader((prevLoader) => {
      if (isLoading && !prevLoader.includes(loaderName)) {
        return [...prevLoader, loaderName];
      }
      if (!isLoading) {
        return prevLoader.filter((item) => item !== loaderName);
      }
      return prevLoader;
    });
  };

  // Roles management
  const roles = localStorage.getItem('roles');
    // const roles = "GG_FGI_Employee,GG_Forage_Genetics_Master"; // for local testing
  const rolesArray = roles?.split(',');

  // GraphQL Queries
  const { error: masterDataError, data: masterDatas, loading: masterDataLoading } = useQuery<ILOLMasterData>(MASTERDATA_QUERY);
  const { error: brandsDataError, data: brandsData, loading: brandsLoading } = useQuery<IBrandsData>(BRAND_QUERY, {
    variables: { roleNames: rolesArray },
  });

  // Effect to handle loading states
  useEffect(() => {
    checkLoading(masterDataLoading, 'masterDataLoading');
    checkLoading(brandsLoading, 'brandsLoading');
  }, [masterDataLoading, brandsLoading]);

  // Effect to set brandList and masterData once data is loaded
  useEffect(() => {
    if (brandsData) {
      setBrandList(brandsData.lOLBrandsCollection.items.filter(a => a.roles.items.length > 0));
    }
    if (masterDatas) {
      setMasterData(masterDatas);
    }
  }, [brandsData, masterDatas]);

  // Error handling
  useEffect(() => {
    if (masterDataError) {
      console.log('Error fetching master data:', masterDataError);
    }
    if (brandsDataError) {
      console.log('Error fetching brands data:', brandsDataError);
    }
  }, [masterDataError, brandsDataError]);

  // Memoize the state to prevent unnecessary re-renders
  const state = useMemo(() => ({
    brandList,
    masterData,
    userName,
    setUserName,
    token,
    setToken,
    loader,
    setLoader,
  }), [brandList, masterData, userName, token, loader]);

  return (
    <AppContext.Provider value={state}>
      {loader && loader.length > 0 && <Loader />}
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
